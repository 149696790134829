body {background: $bg-color;padding: 0; margin: 0;font-family: $basefont;font-weight: 300;line-height: 1.4;color: $basefont-color;font-size: $basefont-size;-webkit-font-smoothing: antialiased;-moz-osx-font-smoothing: grayscale;}

a {cursor:pointer;}
a, a:active {outline: none}

/*ul{list-style: none; margin: 0; padding: 0;}*/

p {margin: 0;}
img {max-width: 100%;height: auto;}
.left {float: left !important;}
.right {float: right !important;}
.text-left {text-align: left;}
.text-right {text-align: right;}
.text-center {text-align: center;}
.uppercase {text-transform: uppercase;}
.clearfix {clear: both;}
.block {display: block}
.clearsmall {display: none;}
.row {max-width: 1190px;width: 100%;position: relative;margin: 0 auto;display: block;}
.color-red {color: $red;}

.ccm-ui, .ccm-area {letter-spacing: normal !important;}

input[type=text]::-ms-clear {
    display: none;
}

.fixed {position: fixed !important;z-index: 100;}
.relative {position: relative}
.hide {display: none;}
.soft-hide {display: none;}
.show-mobile {display: none;}
.hide-mobile {display: block;}
*, *:before, *:after {-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box; }
.font10 {font-size: 10px;}
.font16 {font-size: 16px;}
.font18 {font-size: 18px;}
.font32 {font-size: 32px;}
.fs30{font-size: 30px;}
.fs48{font-size: 48px;}


p.kisbetus{font-size: 12px; line-height: 16px;}

h1 {margin: 0 0 10px 0;font-weight: 300;}
h2 {font-size: 31px;line-height: 1;margin: 0 0 10px 0;font-weight: 300;}
h3 {font-size: 20px;margin-bottom: 5px;font-weight: 400;letter-spacing: 0.3px;}
h4 {font-size: 16px;margin: 0 0 5px;}

.lh30{line-height: 30px;}

.col3 {float: left;width: 25%;padding: 0 10px;}
.col4 {float: left;width: 33.33%;padding: 0 10px;}
.col6 {float: left;width: 50%;padding: 0 10px;}
.col8 {float: left;width: 66.66%;padding: 0 10px;}
.col9 {float: left;width: 75%;padding: 0 10px;}

.athuzott{text-decoration: line-through;}

.full-width {float: left;width: 100%;}

.nopadding {padding: 0;}
.nowrap {white-space: nowrap;}

.pr30 {padding-right: 2.5%;}
.pl30 {padding-left: 2.5%;}
.ptop10 {padding-top:10px;}
.ptop20 {padding-top:20px;}
.ptop30 {padding-top:30px;}
.ptop45 {padding-top:45px;}
.ptop75 {padding-top:75px;}
.ptop5 {padding-top: 5px;}

.pb10 {padding-bottom: 10px;}
.pb20 {padding-bottom: 20px;}
.pb25 {padding-bottom: 25px;}
.pb30 {padding-bottom: 30px;}
.pb40 {padding-bottom: 40px;}
.pb45 {padding-bottom: 45px;}
.pb50 {padding-bottom: 50px;}

.mt10 {margin-top: 10px;}
.mt20 {margin-top: 20px;}
.mt30 {margin-top: 30px;}
.mt40 {margin-top: 40px;}
.mt50 {margin-top: 50px;}
.mt60 {margin-top: 60px !important;}
.mt70 {margin-top: 70px;}
.mt80 {margin-top: 80px;}

.mb10 {margin-bottom: 10px;}
.mb20 {margin-bottom: 20px;}
.mb25 {margin-bottom: 25px;}
.mb30 {margin-bottom: 30px;}
.mb40 {margin-bottom: 40px;}
.mb45 {margin-bottom: 45px;}
.mb50 {margin-bottom: 50px;}
.mb60 {margin-bottom: 60px;}
.mb70 {margin-bottom: 70px;}
.mb80 {margin-bottom: 80px;}
.mb90 {margin-bottom: 90px;}

.w100 {width: 100%;}

input:focus, textarea:focus {box-shadow: none;}
.f10 {font-size: 10px;}
.f12 {font-size: 12px;}
.f14 {font-size: 14px;}

.nomargin {margin: 0;}
/*
select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}
*/
input::-webkit-input-placeholder { padding-left: 0; font-size: 13px;line-height: 27px; }
input:-moz-placeholder { padding-left: 0; font-size: 13px;line-height: 27px; }
input::-moz-placeholder { padding-left: 0; font-size: 13px;line-height: 27px; }
input:-ms-input-placeholder { padding-left: 0; font-size: 13px;line-height: 27px; }

.button {display: inline-block;background: $red;color: #fff;font-size: 14px;line-height: 32px;border-radius: 8px;padding: 0 45px 0 10px;text-decoration: none; position: relative;
	&:after{content: "";width: 7px; position: absolute; top: 0; bottom: 0; right: 13px; background: url(images/button_arrow.svg) no-repeat center;}
}
.errorredborder {border: 1px solid $red !important;}
.error {color: $red;font-size: 12px;padding-top: 4px;}
