@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700&subset=latin-ext');
@font-face {
    font-family: 'Poppins', sans-serif;
    /*src: url('fonts/Questrial-Regular-hu.ttf');
    src: local('☺'),
         url('fonts/Questrial-Regular-hu.ttf') format('truetype'),*/
}

$basefont:  'Poppins';

$basefont-color:    #292929;
$bg-color:          #fff;
$dark-bg:           $basefont-color;
$white:             #dbdbdb;
$red :              #ed1828;
$border-color:      #cfcfcf;

$basefont-size:     13px;
//$basefont-color:    #3A3A3A;
$tartalom-font-color: #686868;

@mixin transform($sg){
  -webkit-transform: $sg;
  -moz-transform: $sg;
  -ms-transform: $sg;
  -o-transform: $sg;
  transform: $sg;
}

@mixin translate($x:0,$y:0){
  -webkit-transform: translate($x,$y);
  -moz-transform: translate($x,$y);
  -ms-transform: translate($x,$y);
  -o-transform: translate($x,$y);
  transform: translate($x,$y);
}

@mixin pricebg(){
  background : -moz-linear-gradient(50% 100% 90deg,rgba(242, 242, 242, 1) 0%,rgba(255, 255, 255, 1) 100%);
  background : -webkit-linear-gradient(90deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0,rgba(242, 242, 242, 1) ),color-stop(1,rgba(255, 255, 255, 1) ));
  background : -o-linear-gradient(90deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background : -ms-linear-gradient(90deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#F2F2F2', endColorstr='#FFFFFF' ,GradientType=0)";
  background : linear-gradient(0deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFF',endColorstr='#F2F2F2' , GradientType=0);
}

@mixin pagebg(){
  background : -moz-linear-gradient(0% 50% 0deg,rgba(242, 242, 242, 1) 0%,rgba(255, 255, 255, 1) 100%);
  background : -webkit-linear-gradient(0deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background : -webkit-gradient(linear,0% 50% ,100% 50% ,color-stop(0,rgba(242, 242, 242, 1) ),color-stop(1,rgba(255, 255, 255, 1) ));
  background : -o-linear-gradient(0deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
  background : -ms-linear-gradient(0deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#F2F2F2', endColorstr='#FFFFFF' ,GradientType=0)";
  background : linear-gradient(90deg, rgba(242, 242, 242, 1) 0%, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F2F2F2',endColorstr='#FFFFFF' , GradientType=1);
}

@mixin gradline(){
  background: #ff0928;
  background: -moz-linear-gradient(left, #ff0928 0%, #292929 100%);
  background: -webkit-linear-gradient(left, #ff0928 0%,#292929 100%);
  background: linear-gradient(to right, #ff0928 0%,#292929 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0928', endColorstr='#292929',GradientType=1 );
}

@mixin redToTransparent(){
  background: #ff0928;
  background: -moz-linear-gradient(left, #ff0928 0%, rgba(0,0,0,0) 100%);
  background: -webkit-linear-gradient(left, #ff0928 0%,rgba(0,0,0,0) 100%);
  background: linear-gradient(to right, #ff0928 0%,rgba(0,0,0,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0928', endColorstr='rgba(0,0,0,0)',GradientType=1 );
}

@mixin galleryImgShadow(){
    background : -moz-linear-gradient(50% 100% 90deg,rgba(0, 0, 0, 1) 0%,rgba(0, 0, 0, 0) 100%);
    background : -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0,rgba(0, 0, 0, 1) ),color-stop(1,rgba(0, 0, 0, 0) ));
    background : -o-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    background : -ms-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000' ,GradientType=0)";
    background : linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000',endColorstr='#000000' , GradientType=0);
}