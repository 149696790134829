/*=============MEDIA QUERIES=============*/

@media only screen and (max-width:1250px) {
  .row {
    &.hazhozszallitas {width: 94%;}
    &.tartalom {width: 94%;}
  }
}


@media only screen and (max-width:1230px) {
  .full_width_block .halfblock{padding: 30px;}
  footer {padding: 90px 30px 90px;}
}

@media only screen and (max-width:1024px) {
  .cimlap-slider .slide-container{height: 50vh;}
  .textbox{top:130px;}
  
}

@media only screen and (max-height:768px) {
  .cimlap-slider .slide-container{height: 70vh;}
}

@media only screen and (max-width:960px) {
  .page .container {
    .head-datas {
      .address {width: 100%;}
      .modify {}
    }
    .categories {padding: 0 10px 0 15px;}
    .content .wrapper {
      .orders-col {position: static;float: left;width: 100%;max-width: none;
        h3 {display: none;}
        .orders {
          .list {padding-bottom: 10px;
            hr {margin: 0 0 5px;}
            .row.szallitas {padding: 5px 0;}
          }
          .note {display: none;}

        }
      }
      .inner-content {width: 100%;max-width: none;padding-right: 0;}
    }
  }

}

@media only screen and (max-width:825px) {
  .textbox{display: none;}
  header.home .logo{max-width: 100px; top:20px;}
}

@media only screen and (max-width:768px) {
  .row {
    &.hazhozszallitas {width: 100%;}
    &.tartalom {width: 100%;}
  }
  .page .container .tartalom {padding: 0 25px;}
  .show-mobile {display: block;}
  .hide-mobile {display: none;}
  .page .container {
    .categories {width: 100%;max-width: none;margin: 10px 0;padding-right: 15px;
      .wrapper {padding: 0 10px 10px;border: 1px solid $border-color;}
    }
    .content {width: 100%;padding: 0 15px;
      .wrapper .inner-content .products .list .item.with-pic .texts .allergens {position: static;}
    }
    .head-datas {
      .address {width: 100%;}
      .modify {}
      .right {float: left !important;padding-left: 0;}
      .info {float: right;}
    }
    .formrow input[type="text"], .formrow input[type="password"] {max-width: none;}
    .bottom-cart {}
    #rendeles-elkuldese-form hr {max-width: none;}
    .formrow {
      &.harmad {max-width: none;}
      textarea {max-width: none;}
    }

    .menu {
      .head {font-size: 21px;}
      .list .item {
        .texts .title {font-size: 16px;}
        .price {font-size: 16px;}
      }
    }
  }
}


@media only screen and (max-width:650px){
  .cimlap-slider .slide-container{height: 30vh;}
  .textbox{ top: 30vh; color: $basefont-color; left: 0; right: 0; padding: 30px 15px 0;}
  header.home{position: static;
    .row.cont{height: 85px;}
    .logo{background-image: url(images/logo.svg); background-size: contain;background-repeat: no-repeat; height: 45px;
      img{display: none;}
    }
  }
  .text-box-2.fs48,
  .text-box-2.fs30{
    letter-spacing: 0;
  }
  .full_width_block{
    h3{font-size: 25px; max-width: 100%;}
    p{font-size: 15px;}

  }
  .page .container {
    .esemenyek-block {
      .item {width: 48%;margin-right: 4%;
        &:nth-child(3n) {margin-right: 4%;}
        &:nth-child(2n) {margin-right: 0;}
      }
    }
    .galeria_kont {
      .galeria-item {width: 48%;margin-right: 4%;
        &:nth-child(3n) {margin-right: 4%;}
        &:nth-child(2n) {margin-right: 0;}

      }
      &:nth-child(3) {margin-right: 4%;}
      .kepgaleria {
        .item {display: inline-block;width: 48%;margin-right: 4%;
          &:nth-child(3n) {margin-right: 4%;}
          &:nth-child(2n) {margin-right: 0;}
        }
      }
    }
  }

  .kepes_tartalom_container{
    .full_width_block{
      .halfblock{max-width: 100%; display: block;
        &.pichalf{padding: 0; display: none;
          .bg{position: static; height: 300px; }
        }
      }
    }
  }

  .ajanlo_container{
    .full_width_block{
      .halfblock{max-width: 100%; display: block; padding: 0; padding-bottom: 25px;
        *:not(img){padding-left: 20px;padding-right: 20px; display: block;}
      }
    }
  }
}

@media only screen and (max-width:568px){
  .page .container {
    .head-datas {
      .left, .right {padding-top: 0;width: 50%;}
      .modify {padding: 0 0px 5px 0;font-size: 12px;}
    }
    .menu {padding: 0;}
  }
  .hamb{display: block;}
  header .menu-cont {width: 100%; z-index: 11; position: fixed; background: #fff; top: 0; left: 0; right: 0; height: auto; bottom: 0; padding: 130px 20px 20px; transform: translateX(100%); transition: all 200ms ease-in-out;
    &.open{transform: none;}
  }
  header.home .menu-cont ul.nav li a{color: #2d2d2d;}
  header .menu-cont ul.nav li {display: block;margin-bottom: 20px;}
  header .menu-cont ul.nav li:not(:last-child) {margin-right: 0;}
  header .row.cont{padding: 30px 15px 0;}
}

@media only screen and (max-width:480px) {

  footer { padding: 30px 20px 30px;
    .footer_container .halfblock{
      display: block;
      max-width: 100%;
      &:first-child{margin-bottom: 30px;}
      .button:first-child{margin-bottom: 20px;}
    }
  } 
    

  .page .container .content .wrapper .inner-content .products .list .item {border-left: 1px solid $border-color;border-right: 1px solid $border-color;
    &.with-pic {
      .img-cont {width: 100%;
        img {width: 100%;}
      }
      .texts {width: 100%;min-height: auto;
        .allergens {position: static;}
      }
      .price {min-height: auto;padding: 20px 0;}
    }

    .texts {width: 100%;min-height: auto;
      .allergens {position: static;}
    }
    .price {width: 100%;min-height: auto;border-left: 0;padding: 10px;}

  }
  .page .container .fancy-select-kont.cim {width: 100%;}
  .full_width_block .halfblock{padding: 20px;}

  .page .container {
    .tartalom .pagetitle {padding: 50px 0;}
    .esemenyek-block {
      .item {width: 100%;margin-right: 0;
        &:nth-child(3n) {margin-right: 0;}
        .img-cont {display: block;margin: 0 auto;width: 270px;}
        .text-cont {display: block;margin: 0 auto;width: 270px;}
      }
    }
    .galeria_kont {
      .galeria-item {width: 270px;margin: 0 auto 30px;float: none;
        &:nth-child(3n) {margin-right: auto;}
        &:nth-child(2n) {margin-right: auto;}
      }
      &:nth-child(3) {margin-right: auto;}
      .kepgaleria {
        .item {display: block;width: 270px;margin: 0 auto 30px;float: none;
          &:nth-child(3n) {margin-right: auto;}
          &:nth-child(2n) {margin-right: auto;}
        }
      }
    }
  }
}

@media only screen and (max-width:360px){
  .head-datas .divider {display: none;}
}