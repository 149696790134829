@import "normalize";
@import "variables";
@import "slick";
@import "default_styles";

header {background: $dark-bg;
  .row.cont{height: 115px; background: #fff; padding: 30px 40px 0; letter-spacing: -4px;}
  .logo{max-width: 100px; width: 100%; display: inline-block;}
  span.vert_line {display: inline-block;margin: 0 5px;}
  .menu-cont{display: inline-block;height: 100%;vertical-align: top;text-align: right;width: calc(100% - 100px);letter-spacing: 0;
    ul.nav{margin: 0; padding: 0; list-style: none;
      li{display: inline-block;
        &:not(:last-child){margin-right: 15px;}
        a{text-decoration: none; color: #2d2d2d;}
        &.nav-selected{ position: relative;
          a{color: $red;}
          &:after{content: "";width: 8px; height: 8px; display: inline-block; background: $red; border-radius: 50%; position: absolute; top: 20px; left: 2px;}
        }
      }
    }
    .social_line{margin-bottom: 15px;
      .socicon{background-image: url('images/social_icons.svg'); display: inline-block; vertical-align: middle; background-repeat: no-repeat; margin-right: 10px;
        &.tripad{width: 30px; height: 19px; background-position-x: 0px;}
        &.instagram{width: 18px; height: 18px;background-position-x: -40px;}
        &.fb{width: 6px; height: 13px;background-position-x: -67px;}
      }
      .langselect{ font-weight: 400; color: #7c7c7c; display: inline-block; margin-left: 15px;
        a{text-decoration: none;color: #7c7c7c;}
      }
    }
  }
  &.home{ background: transparent; position: absolute; left: 0; right: 0; z-index: 1;
    .grad_line{@include redToTransparent(); max-width: 100%;}
    .logo{max-width: 150px; position: absolute; top: 60px;}
    .row.cont{background: transparent;}
    .menu-cont ul.nav li a{color: #dbdbdb;}
    .menu-cont{width: 100%;}
    .hamb .hambline{background: $red;}
  }
}

.grad_line{
  @include gradline();height: 8px;
}

.hamb{width: 30px; height: 22px; position: absolute; right: 15px; top: 50%; transform: translateY(-50%); z-index: 100; display: none;
  .hambline{position: absolute; width: 100%; height: 3px;background: #3A3A3A; transition: all 200ms ease-in-out;
    &:first-child{top: 0;}
    &:nth-child(2){top: 50%; transform: translateY(-50%);}
    &:last-child{bottom: 0;}
  }
  &.open{ height: 30px;
    .hambline{ background: $red;
      &:first-child{top: 50%; transform: rotate(-45deg) translateY(-50%);}
      &:nth-child(2){opacity: 0;}
      &:last-child{bottom: 50%;transform: rotate(45deg) translateY(50%);}
    }
  }
}

.textbox{max-width: 360px; width: 100%;position: absolute;right: 0;top: 28%;height: auto; font-weight: 400; text-transform: uppercase;color: white;}
.text-box-1{max-width: 85px; width: 100%; display: inline-block; vertical-align: top; opacity: 0; transition: all 800ms ease-in-out;
  .numb{font-size: 48px; line-height: 41px;}
  &.smaller .numb {font-size: 34px;}
  .year{font-size: 24px;}
  &.smaller .year{font-size: 20px;}
  &.visible{opacity: 1}
}
.text-box-2{vertical-align: top;-webkit-writing-mode: tb-rl;-ms-writing-mode: tb-rl;writing-mode: tb-rl;-webkit-text-orientation: upright;-ms-text-orientation: upright;text-orientation: upright;display: inline-block;letter-spacing: 4px; position: relative; top: -5px; line-height: 0; line-height: 33px; opacity: 0; transition: all 800ms ease-in-out;
  &.fs30{letter-spacing: -10px; top: -12px; line-height: 36px;}
  &.fs48{letter-spacing: -20px; top: -20px; line-height: 54px;}
  &.visible{opacity: 1}
}
.text-box3 {display: inline-block;max-width: 90px; opacity: 0; transition: all 800ms ease-in-out;
  &.visible{opacity: 1}
}

.cimlap-slider{ //max-width: 100%; overflow: hidden;
  img{width: 100%;}
  .slide-container{position: relative; height: 80vh; width: 100%; background-size: cover;
  }
}

.textbox.en{
  .text-box-2,
  .text-box-2.fs30{
    display: block; line-height: 28px; letter-spacing: 0;
    -webkit-text-orientation: upright;text-orientation: upright;-webkit-writing-mode: unset;-ms-writing-mode: unset;writing-mode: unset;
  }
}

.page {background: $dark-bg;
  .container {max-width: 1280px;width: 100%;background: $dark-bg;margin: 0 auto;
    .hazhozszallitas {background: #fff;}
    .menu {background: #fff;padding: 0 45px;color: $tartalom-font-color;border-top: 4px solid $border-color;}
    .tartalom {background: #fff;padding: 0 45px;color: $tartalom-font-color;
      .center {max-width: 890px;margin: 0 auto;}
      .pagetitle {font-size: 31px;line-height: 1.2;padding: 50px 45px;text-align: center;border-top: 4px solid $border-color;}
    }
    .categories {max-width: 270px;width: 23%;float: left;vertical-align: top;padding: 0 10px 0 40px;position: relative;
      .toggle-cont {background: $white;height: 40px;position: relative;
        p {padding: 10px;}
        .toggle {position: absolute;right: 15px;top: 15px;width: 0;height: 0;border-style: solid;border-width: 10px 7.5px 0 7.5px;border-color: #3a3a3a transparent transparent transparent;
          &.open {@include transform(rotate(180deg));}
        }
      }
      .all {padding-top: 20px;color: $red;padding-bottom: 25px;display: inline-block;text-decoration: none;font-size: 14px;}
      .category {padding-bottom: 25px;
        ul {list-style: none; margin: 0; padding: 0;
          li {
            a {line-height: 1.7;text-decoration: none;color: $basefont-color;
              &.name {font-weight: 400;}
              &.aktiv {color: $red;}
            }
          }
        }
      }
    }

    .head-datas {background: $dark-bg;color: $white;padding: 12px 15px;width: 100%;float: left;
      .left {max-width: 655px;width: 73%;padding: 4px 15px 0 0;}
      .right {max-width: 260px;width: 27%;padding-left: 15px;}
      .address {width: 71%;float: left;font-size: 14px;}
      .modify {color: $white;display: inline-block;padding-top: 5px;}
      .logout {color: $white;}
      .info {float: left;font-size: 14px;font-weight: 400;}
    }

    .content {max-width: 920px;width: 77%;float: right;
      .wrapper {position: relative;float: left;width: 100%;
        .inner-content {max-width: 655px;width: 72%;padding-right: 20px;float: left;min-height: 600px;
          .delivery-info {padding: 10px 0 5px;}
          .price-info {padding: 20px 0;}
          hr {border: 0;border-top: 1px solid $border-color;}

          .products {
            .head {background: #666666;color: $white;line-height: 42px;font-size: 20px;padding-left: 15px;font-weight: 400;}
            .list {padding: 15px 0;border-top: 1px solid $border-color;
              .item {border-bottom: 1px solid $border-color;margin-bottom: 15px;
                &:first-child {border-top: 1px solid $border-color;}
                .texts {padding: 10px 15px;float: left;width: 77%;background: #fff;min-height: 90px;
                  .title {font-size: 20px;font-weight: 400;}
                  .description {font-size: 13px;}
                  .allergens {font-size: 11px;}
                }
                .price {font-size: 18px;text-align: center;display: inline-block;float: left;width: 23%;text-decoration: none;color: $basefont-color;min-height: 90px;@include pricebg();padding-top: 35px;border-left: 1px solid $border-color;font-weight: 400;position: relative;
                  .plus {color: $red;margin-left: 5px;border: 1px solid $red;display: inline-block;padding: 0 2px;border-radius: 5px;line-height: 18px;}
                  .akcio {display: inline-block;height: 20px;background: $red;position: absolute;color: #fff;right: 0;top:0;padding: 2px 5px;font-size: 13px;}
                  .athuzott {text-decoration: line-through;font-size: 13px;color: lighten($basefont-color, 10%);position: absolute;top: 22%;left: 0;right: 0;}
                }

                &.with-pic {border-bottom: 2px solid $border-color;
                  .img-cont {display: inline-block;float: left;width: 38%;
                    img {float: left;}
                  }
                  .texts {width: 39%;min-height: 159px;position: relative;
                    .allergens {position: absolute;bottom: 10px;}
                  }
                  .price {min-height: 159px;padding-top: 65px;
                    .athuzott {top: 28%;}
                  }
                }
                &.mar-rendelted {margin-bottom: 0;}
              }
            }
          }

        }
        .orders-col {max-width: 260px;width: 28%;background: $white;float: right;padding: 20px;position: absolute;top: 0px;right: 0px;bottom: 0px;
          h3 {margin: 5px 0 20px;}
          .orders {
            .list {padding-bottom: 25px;
              .row {
                .remove {display: inline-block;color: #fff;background: $red;width: 13px;height: 13px;border-radius: 50%;text-align: center;text-decoration: none;position: relative;vertical-align: top;
                  span {position: absolute;top: -3px;left: 0;right: 0;}
                }
                .num {color: $red;display: inline-block;padding: 0 2px;vertical-align: top;}
                .name {max-width: 60%;display: inline-block;}
                .price {float: right;}
                &.szallitas {padding: 20px 0;}
              }
              .total {color: $red;font-size: 18px;font-weight: 400;text-align: right;padding-top: 13px;}
              hr {margin: 0 0 12px;}
            }
            .note {font-size: 11px;padding-top: 30px;}
            .button.inactive {background: #ebebeb;color: #a1a1a1;}
          }
        }
      }
    }

    .formrow {margin-bottom: 15px;
      input[type="text"], input[type="password"] {height: 30px;border: 1px solid $border-color;line-height: 30px;padding-left: 15px;max-width: 350px;width: 100%;}
      &.harmad {max-width: 350px;
        input[type="text"] {width: 30%;margin-right: 5%;float: left;
          &:nth-of-type(3) {margin-right: 0;}
        }
      }
      textarea {border: 1px solid $border-color;max-width: 350px;width: 100%;height: 85px;padding: 10px 15px;}
      .add-address {display: inline-block;margin-top: 5px;}
      &.mb25 {margin-bottom: 25px;}
    }

    .content .szemelyes-adatok {
      hr {max-width: 350px;width: 100%;}
    }

    .rendeles-table {width: 100%;margin-top: 30px;
      thead th {border-bottom: 1px solid $border-color;}
      tr {
        td {padding: 5px;border-bottom: 1px solid $border-color;}
      }
    }

    .fuji-layer {position: fixed;width: 100%;height: 100%;background: rgba(0, 0, 0, 0.7);display: none;left: 0;top: 0;z-index: 991;
      .dialog {position: absolute;top: 50%;left: 0;right: 0;margin: 0 auto;width: 300px;background: #fff;padding: 20px;@include translate(0, -50%);}
    }

    .fb_iframe_widget {margin-left: 15px;}

    .bottom-cart {position: fixed;bottom: 0;left: 0;width: 100%;background: $white;padding: 15px;z-index: 10;display: none;
      p {padding-top: 6px;float: left;}
      .button {float: right;}
    }

    .fancy-select-kont {width: 225px;position: relative;height: 38px;
      select {height: 32px;opacity: 0;width: 100%;}
      .fancy-select-display {background: url(images/select-bg.svg);height: 33px;padding: 7px 0 0 20px;position: absolute;white-space: nowrap;overflow: hidden;width: 227px;
        &:after{content: "";width: 7px; position: absolute; top: 0; bottom: 0; right: 13px; background: url(images/select_arrow.svg) no-repeat center 9px;}
      }
      &.cim {float: left;margin-right: 20px;}
    }

    .fancy-checkbox {margin-right: 20px;
      input[type="checkbox"], input[type="radio"] {display: none;}
      .radio-indikator {border-radius: 50%; width: 13px; height: 13px; display: inline-block; background: #fff; border: 1px solid #b7b7b7; vertical-align: middle; margin-right: 10px;}
      .checkbox-indikator {border-radius: 2px; width: 16px; height: 14px; display: inline-block; background: #fff; border: 1px solid #b7b7b7; vertical-align: middle; margin-right: 10px;}
      input[type="checkbox"]:checked + .checkbox-indikator {background: url(images/checked2.svg) #fff no-repeat;}
      input[type="radio"]:checked + .radio-indikator {background: #474440;}
    }

    #rendeles-elkuldese-form {
      .formrow p {margin-bottom: 10px;}
      hr {max-width: 350px;}
    }

    .galeria_kont {padding-bottom: 50px;
      .galeria-item {width: 31%;margin-right: 3.5%;float: left;position: relative;margin-bottom: 30px;
        a {display: inline-block;width: 100%;
          .title {display: inline-block;position: absolute;font-size: 18px;line-height: 30px;color: #fff;left: 25px;bottom: 15px;}
          .shadow {@include galleryImgShadow();position: absolute;left: 0;bottom: 0;display: inline-block;width: 100%;height: 110px;}
        }
        &:nth-child(3n) {margin-right: 0;}
      }
      &:nth-child(3) {margin-right: 0;}
      .kepgaleria {
        .item {display: inline-block;width: 31%;margin-right: 3.5%;float: left;margin-bottom: 30px;
          &:nth-child(3n) {margin-right: 0;}
        }
      }
    }

    .esemenyek-block {padding-bottom: 50px;
      .item {display: inline-block;width: 31%;margin-right: 3.5%;float: left;margin-bottom: 30px;text-decoration: none;
        &:nth-child(3n) {margin-right: 0;}
        .img-cont {position: relative;display: inline-block;
          .shadow {@include galleryImgShadow();position: absolute;left: 0;bottom: 0;display: inline-block;width: 100%;height: 110px;}
          .title {text-decoration: none; color: #fff; text-transform: uppercase;font-size: 11px; position: absolute;left: 15px;bottom: 10px;background: url('images/red_arrow.svg') no-repeat;padding-left: 20px;display: inline-block;line-height: 14px;}
        }
        .text-cont {display: inline-block;width: 100%;font-size: 18px;line-height: 23px;color: $tartalom-font-color;padding-top: 15px;}
      }
    }

    .menu {
      .center {max-width: 890px;margin: 0 auto;padding-top: 85px;}
      .head {font-size: 31px;line-height: 1.2;padding-bottom: 2px;}
      .list {padding-bottom: 45px;
        .item {padding-bottom: 5px;background: url(images/menu_line.svg) repeat-x 0 15px;
          .texts {max-width: 70%;float: left;
            .title {font-size: 18px;line-height: 31px;padding-right: 15px;float: left;background: #fff;}
            .description {font-size: 12px;line-height: 16px;float: left;width: 100%;}
          }
          .price {font-size: 18px;float: right;background: #fff;padding-left: 15px;line-height: 30px;}
        }
      }
    }

  }
}

footer { background: $dark-bg; padding: 90px 0 150px; color: #969696;
  .footer_container{padding-top: 50px; border-top: 1px solid #969696; letter-spacing: -4px;
    .halfblock{display: inline-block; max-width: 50%; width: 100%; letter-spacing: 0; vertical-align: top;
      &:last-child p{margin-bottom: 30px;}
    }
    img {vertical-align: middle;}
	a:not(.button) {color: #ed1828; text-decoration: none;}
  }
}


.full_width_block {letter-spacing: -4px; position: relative;
  h3{font-size: 31px;margin: 0; max-width: 50%;}
  p{font-size: 18px;}
  .halfblock {color: $tartalom-font-color; padding: 80px 50px 80px 135px; display: inline-block; max-width: 50%; width: 100%; vertical-align: top; letter-spacing: 0; height: 100%;
    .bg{top: 0; left: 0; right: 50%;bottom: 0; position: absolute; background-repeat: no-repeat; background-size: cover; background-position: center;
      &.jobb{left: 50%; right: 0;}
    }
    a{text-decoration: none; color: $red;}
    .btn{text-decoration: none; color: $red; text-transform: uppercase;font-size: 11px; line-height: 13px; letter-spacing: 4px;
      /*&:before{content: ""; display: block; height: 14px; margin-bottom: 8px;background-image: url('images/red_arrow.svg'); background-repeat: no-repeat; background-position: left;}*/
	  span.red-arrow {display: block; height: 14px; width: 14px; margin-bottom: 8px;background-image: url('images/red_arrow.svg'); background-repeat: no-repeat; background-position: left;}
    }
  }
  &.dark{
    .halfblock{
      color: #fff;
      background: $basefont-color;
    }
    a.btn{position: relative; text-transform: none; display: inline-block; background: $red; color: #fff; font-size: 14px; line-height: 32px; border-radius: 8px; padding: 0 45px 0 10px;
      &:before{content: ""; display: none;}
      &:after{content: "";width: 7px; position: absolute; top: 0; bottom: 0; right: 13px; background: url(images/button_arrow.svg) no-repeat center;}
	  span.red-arrow {display: none;}
    }
  }
}

.ajanlo_container{background-color: $basefont-color;
  .halfblock{padding: 90px 0 0; color: #fff;}
  h4{font-size: 24px; font-weight: normal; line-height: 26px; margin-bottom: 15px;}
  p{font-size: 15px; margin-bottom: 15px;}
  img{width: 100%; margin-bottom: 25px;}
}

.text_part{opacity: 0; transition: all 500ms ease-in-out;
  img{margin-left: 20px;}
  &.visible{opacity: 1;}
}

select#terulet{max-width: 100%; display: block;}

.modal-cont {background: rgba(0,0,0,0.5);visibility: hidden;  z-index: 1000;position: fixed;left: 0;top: 0;width: 100%;height: 100%;
  &.show {visibility: visible;}
  .hirlevel-form {background: #fff;max-width: 500px;padding: 40px;position: absolute;top: 50%;left: 50%;transform: translateX(-50%) rotateY(-85deg) translateY(-50%);-webkit-transform: translateX(-50%) rotateY(-85deg) translateY(-50%);transition: all 0.3s ease 0s;opacity: 0;width: 90%;
    .close {display: inline-block;width: 40px;height: 40px;background: #636363 url(images/close.png) no-repeat center center;position: absolute;right: 0;top: 0;}
    .title {font-size: 31px;padding: 0 0 30px 0;}
    .sor {float: left;width: 100%;margin-bottom: 15px;
      label {}
      input {width: 100%;margin-top: 5px;height: 35px;}
		&.checkboxos{ text-align: justify;
			label {float: none;padding: 0;width: auto;font-weight: normal;}
			input[type=checkbox] {margin-right: 5px;width: auto;height: auto;}
		}	  
    }
  }
  &.show .hirlevel-form {transform: rotateY(0deg) translateX(-50%) translateY(-50%);-webkit-transform: rotateY(0deg) translateX(-50%) translateY(-50%);opacity: 1;}
}
.newsletter {color: #ed1828; text-decoration: none;}

@import "media_queries";

